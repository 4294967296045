@import "~antd/dist/antd.css";

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.course-status span {
  font-size: 10px;
  margin-bottom: 5px;
}

.ant-modal-body {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
}

.ant-modal-confirm .ant-modal-body {
  overflow-y: auto;
  height: auto;
}

.admin-recent-orders .ant-table-content {
  max-height: 1722px;
  overflow: hidden;
  overflow-y: auto;
}

.admin-recent-orders thead.ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;
}

.dashboard-charts h2 {
  text-transform: uppercase;
  color: #74859c;
  letter-spacing: 2px;
  font-size: 15px;
}

.text-center {
  text-align: center;
}

.link-stats {
  color: #3ea3ff !important;
  cursor: pointer;
  display: block;
}

.table-box .ant-table-thead > tr > th {
  text-transform: uppercase;
  color: #74859c;
  font-size: 11px;
  background: none;
}

.ant-spin-spinning span.anticon {
  color: #0083b7;
}

.course-druation > div {
  width: 50%;
  padding: 0 15px;
}

// .order-course-list :not(:last-child),
// .progress-course-list p:not(:last-child) {
//   border-bottom: 1px solid #ddd;
//   padding-bottom: 5px;
//   padding-top: 5px;
// }

.recent-courses-progress table tr:nth-child(3) .ant-table-cell,
.recent-courses-progress table tr:nth-child(4) .ant-table-cell,
.recent-courses-progress table tr:nth-child(5) .ant-table-cell {
  padding: 0px;
}

.recent-courses-progress
  table
  tr:nth-child(3)
  .ant-table-cell
  .progress-course-list,
.recent-courses-progress
  table
  tr:nth-child(4)
  .ant-table-cell
  .progress-course-list,
.recent-courses-progress
  table
  tr:nth-child(5)
  .ant-table-cell
  .progress-course-list {
  padding: 10px 0px;
}

.recent-courses-progress
  table
  tr:nth-child(3)
  .ant-table-cell
  .progress-course-list
  p,
.recent-courses-progress
  table
  tr:nth-child(4)
  .ant-table-cell
  .progress-course-list
  p,
.recent-courses-progress
  table
  tr:nth-child(5)
  .ant-table-cell
  .progress-course-list
  p {
  min-height: 50px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.recent-courses-progress
  table
  tr:nth-child(3)
  .ant-table-cell
  .progress-course-list
  p:last-child,
.recent-courses-progress
  table
  tr:nth-child(4)
  .ant-table-cell
  .progress-course-list
  p:last-child,
.recent-courses-progress
  table
  tr:nth-child(5)
  .ant-table-cell
  .progress-course-list
  p:last-child {
  border-bottom: none;
}

.custom-date-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.course-druation {
  display: flex;
  margin: 0 -15px 15px;
}

.userActionBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.search {
  width: calc(100% - 100px);

  span.ant-input-suffix .ant-input-clear-icon {
    color: red;
    font-size: 18px;
  }
}

.activityLogs {
  margin-top: 10px !important;
  overflow: auto;
  height: 300px;
  padding: 10px 5px !important;

  .dateTime {
    color: rgb(1, 45, 131);

    span {
      color: rgba(0, 0, 0, 0.6);
      margin-left: 20px;
    }
  }

  .smalldesc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
  }

  .ant-timeline-item-tail {
    left: 30px;
  }

  .ant-timeline-item-content {
    margin-left: 80px;
  }

  .ant-timeline-item .ant-timeline-item-head-custom {
    border: 5px solid #dcd4d4 !important;
    border-radius: 50% !important;
    background-color: rgb(1, 45, 131);
    left: 30px;
    height: 50px;
    width: 50px;
    display: flex;
    top: 20px;
    justify-content: center;
    align-items: center;

    span {
      font-size: 20px;
      color: #fff;
    }
  }
}

.userActionBtns button {
  width: 30px;
  padding: 0;
  transition: ease all 0.25s;
}

.userActionBtns .ant-space-item:nth-child(n + 4) button {
  margin-top: 15px;
}

p.not-found-message {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74859c;
  letter-spacing: 2px;
  font-size: 17px;
}

.chart-box {
  padding: 10px;
  border: 1px solid #ececec;
  height: 250px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
}

.table-box {
  padding: 0 10px;
  border: 1px solid #ececec;
  // height: 350px;
  background: #fff;
  display: block;
  width: 100%;
  // overflow-y: scroll;
  border-radius: 10px;

  .processing-loader {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin-spinning span.anticon {
      color: #0083b7;
      font-size: 40px;
    }
  }
}

.dashboard-charts {
  // display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.users-recent td.ant-table-cell,
.dashboard-charts td.ant-table-cell {
  padding: 11px 10px;
  border-right: 1px solid;
  border-color: #ebebeb;
  font-size: 13px;
}

.users-recent .table-buttons,
.dashboard-charts .table-buttons {
  display: flex;
}

.course-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.users-recent td.ant-table-cell:last-child,
.dashboard-charts td.ant-table-cell:last-child {
  border-right: 0;
}

.users-recent tr:last-child td.ant-table-cell,
.dashboard-charts tr:last-child td.ant-table-cell {
  border-bottom: 0;
}

.users-recent td.ant-table-cell p,
.dashboard-charts td.ant-table-cell p {
  margin: 0;
  color: #001529;
  font-size: 12px;
}

.sidebar li.blogsmenuwrapper span,
.sidebar li.ant-menu-item a {
  color: #fff !important;
  text-transform: uppercase;
  font-size: 12px;
}

.sidebar li.blogsmenuwrapper:hover i {
  color: #fff !important;
}

.ant-layout-sider-trigger {
  background: #fff;
  font-weight: 900;
  color: rgb(1, 45, 131);
}

.sidebar li.ant-menu-submenu.ant-menu-submenu-selected,
.sidebar li.ant-menu-item.ant-menu-item-selected,
.sidebar li.ant-menu-item.ant-menu-item-active,
.sidebar li.ant-menu-submenu-active > div.ant-menu-submenu-title,
.sidebar li.ant-menu-submenu-open > div.ant-menu-submenu-title {
  background: #51c5ff !important;
  color: #fff !important;
  font-weight: 700;
}

//  .logo {
//   height: 32px;
//   margin: 16px;
//   background: rgba(255, 255, 255, 0.3);
// }

// // .site-layout .site-layout-background {
// //   background: #fff;
// // }
//   [data-theme="dark"] .site-layout .site-layout-background {
//     background: #141414;
//   }

p.desc {
  white-space: pre-line;
}

.numberCard {
  padding: 32px 0;
  margin-bottom: 24px;
  cursor: pointer;
}

.iconWarp {
  font-size: 54px;
  float: left;
}

.content {
  width: 100%;
  // text-align: center;
  // padding-left: 78px;
}

.title {
  line-height: 16px;
  font-size: 16px;
  margin-bottom: 8px;
  height: 16px;
}

.number {
  line-height: 32px;
  font-size: 17px;
  height: 32px;
  margin-bottom: 0;
  word-wrap: break-word;
}

.stats {
  display: flex;
  height: 100%;
  flex-direction: row;
  // height:100%;
  background: #fff;
  border-radius: 10px;
  justify-content: space-between;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 26px -12px rgba(0, 0, 0, 0.5);
}

.main-manager-stats-wrapper,
.user-profile .user-profile-details,
.user-profile .user-course-details {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  justify-content: space-between;
  border: 1px solid #ddd;
  // margin: 0 15px;
  box-shadow: 0px 0px 26px -12px rgba(0, 0, 0, 0.5);
  transition: ease all 0.25s;
}

.main-manager-stats-wrapper .manager-stats-wrapper .heading h4 {
  font-size: 14px;
  transition: ease all 0.25s;
  color: #2466e8;
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
}

.main-manager-stats-wrapper .manager-stats-wrapper .heading h3 {
  color: #2466e8;
  transition: ease all 0.25s;
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.main-manager-stats-wrapper .manager-stats-wrapper .response h2 {
  font-size: 37px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  transition: ease all 0.25s;
  line-height: 1;
  color: #002265;
  margin-top: 10px;
}

.main-manager-stats-wrapper .m-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.main-manager-stats-wrapper .m-btn-wrapper .m-green-btn {
  margin-bottom: 10px;
  background: #13a300;
  padding: 10px 0;
  text-align: center;
  line-height: 1;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #13a300;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0);
  transition: ease all 0.25s;
  cursor: pointer;
  width: 100%;
  height: auto;
  text-transform: uppercase;
  font-weight: 600;
}

.main-manager-stats-wrapper .m-btn-wrapper > div {
  width: 100%;
}

.main-manager-stats-wrapper .m-btn-wrapper .m-blue-btn {
  background: #0083b7;
  padding: 10px 0;
  line-height: 1;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  border: 1px solid #0083b7;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0);
  transition: ease all 0.25s;
  cursor: pointer;
  width: 100%;
  height: auto;
  text-transform: uppercase;
  font-weight: 600;
}

.main-manager-stats-wrapper .m-btn-wrapper .m-green-btn:hover {
  background: rgba(0, 0, 0, 0);
  color: #13a300;
}

.main-manager-stats-wrapper .m-btn-wrapper .m-blue-btn:hover {
  background: rgba(0, 0, 0, 0);
  color: #0083b7;
}

.user-profile .course-img img {
  width: 100%;
}

.user-profile .card-heading {
  text-transform: uppercase;
  color: #74859c;
  letter-spacing: 2px;
  font-size: 15px;
  margin-bottom: 25px;
}

.user-profile .user-profile-details {
  padding-right: 15px;
}

.user-profile .user-profile-details .field-wrapper label.label {
  margin-left: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: block;
}

.user-profile .user-profile-details .field-wrapper .p-wrapper {
  position: relative;
}

.user-profile .user-profile-details .field-wrapper .p-wrapper .ctc-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  margin: auto;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  transition: ease all 0.25s;
}

.user-profile .user-profile-details .field-wrapper .p-wrapper .ctc-btn:hover {
  color: #0083b7;
}

.user-profile .user-profile-details .field-wrapper p.details {
  padding: 10px 15px;
  background: #fff;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.user-profile .user-profile-details .field-wrapper button p.details {
  cursor: pointer;
}

.user-profile .user-profile-details .field-wrapper p.address-field {
  min-height: 150px;
}

.user-profile .user-course-details {
  padding-left: 15px;
}

.user-profile .user-course-details .courses-wrapper {
  max-height: 65vh;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px;
  background: rgba(0, 0, 0, 0.01);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.01);
}

.user-profile .user-course-details .courses-wrapper.no-courses {
  display: flex;
  background: none;
  box-shadow: none;
  align-items: center;
  justify-content: center;
}

.user-profile .user-course-details .courses-wrapper::-webkit-scrollbar {
  width: 1px;
}

.user-profile .user-course-details .courses-wrapper::-webkit-scrollbar-thumb {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.user-profile .user-course-details .courses-wrapper .course-box {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 40px;
}

.user-profile .user-course-details .courses-wrapper .course-box:last-child {
  margin-bottom: 0;
}

.user-profile .user-course-details .courses-wrapper .course-box .course-desc {
  padding-left: 30px;
  position: relative;
  height: 100%;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .course-title {
  font-weight: 700;
  margin: 15px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  label {
  text-transform: uppercase;
  color: #74859c;
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 15px;
  display: block;
}

.user-profile .user-course-details .courses-wrapper .course-box .course-desc p {
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .remove-course {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  border: none;
  background: rgba(0, 0, 0, 0);
  color: red;
  box-shadow: none;
  font-size: 15px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .remove-course:hover {
  color: white;
  background: red;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .course-certificate {
  position: absolute;
  right: 40px;
  top: 0;
  z-index: 9;
  border: none;
  background: rgba(0, 0, 0, 0);
  color: #0083b7;
  box-shadow: none;
  font-size: 15px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .course-certificate:hover {
  color: white;
  background: #0083b7;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .download-certificate {
  right: 0;
  bottom: 0;
  border-radius: 5px;
  text-transform: uppercase;
  color: #74859c;
  letter-spacing: 2px;
  font-size: 12px;
  padding: 10px 15px;
  height: auto;
  font-weight: 500;
  border: 1px solid #ddd;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .userDC-btn {
  margin: 0;
  margin-left: auto;
  border: none;
  color: #fff;
  background: #0083b7;
  border: 1px solid #0083b7;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .userDC-btn:hover {
  background: #fff;
  color: #0083b7;
}

.user-profile .edit-profile-btn {
  margin: 0;
  margin-left: auto;
  border: none;
  color: #fff;
  background: green;
  border: 1px solid green;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.user-profile .edit-profile-btn:hover {
  background: #fff;
  color: green;
}

.user-profile .user-course-details .courses-wrapper .course-box .course-desc * {
  position: unset;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  input.ant-checkbox-input {
  position: absolute;
  float: left;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .checkboxWrapper
  * {
  position: relative;
}

.user-profile
  .user-course-details
  .courses-wrapper
  .course-box
  .course-desc
  .download-certificate:hover {
  background: #0083b7;
  color: #fff;
  outline: none;
  box-shadow: none;
  border-color: #0083b7;
}

.table-box,
.chart-box {
  box-shadow: 0px 0px 30px -25px rgba(0, 0, 0, 0.5);
  border: 1px solid #ddd;
}

.stats-heading {
  display: flex;
  border-radius: 10px;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 7px;
  text-align: center;
}

.stats-heading p {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 15%;
  font-size: 14px;
  line-height: 18px;
  color: #0083b7;
}

.stats-heading h3 {
  font-size: 22px;
  color: #002265;
  margin-bottom: 0px;
  font-weight: 700;
}

.stats-heading h2 {
  padding: 26px 16px;
  color: #fff;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}

.table-box.assign-course .ant-table-content {
  overflow: hidden;
  max-height: 45vh;
  overflow-y: auto;
}

.table-box.assign-course {
  box-shadow: none;
  border: none;
}

.table-box.assign-course tr > td {
  border-left: 1px solid #f0f0f0;
}

.table-box.assign-course tr > th:last-child,
.table-box.assign-course tr > td:last-child {
  border-right: 1px solid #f0f0f0;
}

.table-box thead tr > th {
  border: 1px solid #f0f0f0;
  border-left: 0;
}

.table-box thead tr > th:last-child {
  border-right: 0;
}

.table-box.assign-course thead tr > th:first-child {
  border-left: 1px solid #f0f0f0;
}

.table-box.assign-course thead tr > th:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.table-box .ant-table-cell p {
  margin-bottom: 0px !important;
}

.ant-layout-sider-children {
  height: 100%;
  padding-bottom: 0 !important;
  overflow: hidden;
}

.ant-layout-sider-children > ul {
  height: calc(100vh - 180px);
  overflow: hidden;
  overflow-y: auto;
}

.ant-modal-body .ant-form-item-label {
  width: 150px;
  text-align: left;
}

.ant-modal-content {
  max-height: 90vh;
  background: #fff;
  overflow: auto;
  margin: auto;
}

.ant-modal-centered .ant-modal {
  width: 100% !important;
  max-width: 60%;
}

.main-wrapper {
  max-width: calc(100% - 80px);
  margin-left: auto;
  transition: ease all 0.25s;
}

.main-wrapper.menu-open {
  max-width: calc(100% - 200px);
  margin-left: auto;
}

.border {
  border: 1px solid #ececec;
}

// .logo {
// height: 32px;
// margin: 16px;
// background: rgba(255, 255, 255, 0.3);
// }

// .site-layout .site-layout-background {
//   background: #fff;
// }
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(0, 111, 155);
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 0px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  background-clip: content-box;
}

.ind-dashboard .image-wrapper img {
  height: 285px;
  width: auto;
}

.ind-dashboard .admin-image-wrapper img {
  width: 100%;
  height: auto;
}

.ind-dashboard .desc-wrapper span {
  font-weight: 600;
  color: #74859c;
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
}

.ind-dashboard .course-desc-wrapper span {
  text-transform: none;
  color: #000;
}

.ind-dashboard .desc-wrapper button span {
  color: #fff;
}

.ind-dashboard .desc-wrapper h3 {
  font-weight: 600;
  color: #000000;
  text-transform: capitalize;
  font-size: 18px;
  margin: 0;
  margin-bottom: 5%;
}

.ind-dashboard .desc-wrapper .ant-progress-success-bg,
.ind-dashboard .desc-wrapper .ant-progress-bg {
  background: #13a300;
}

.green-btn {
  background: #13a300;
  padding: 5px 10px;
  line-height: 1;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #13a300;
  transition: ease all 0.25s;
  cursor: pointer;
  -webkit-appearance: button;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.green-btn:hover {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #13a300;
  color: #13a300;
}

button.ant-btn.block.green-btn {
  display: inline-block;
}

.blue-btn {
  background: rgb(1, 45, 131);
  padding: 5px 10px;
  line-height: 1;
  border-radius: 5px;
  color: #fff;
  border: 1px solid rgb(1, 45, 131);
  transition: ease all 0.25s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: button;
  height: 32px;
}

.blue-btn:hover {
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgb(1, 45, 131);
  color: rgb(1, 45, 131);
}

.manager-courses-btns-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.manager-courses-btns-wrapper a,
.manager-courses-btns-wrapper button {
  width: 35%;
  text-align: center;
  padding: 0;
}

.ind-dashboard .desc-wrapper p {
  font-size: 12px;
  line-height: 1;
  margin-top: 5%;
}

.ind-dashboard .course-desc-wrapper p {
  margin-top: 0px;
  line-height: 1.3;
  display: inline;
  font-size: 15px;
  font-weight: 400;
}

.ind-dashboard .course-desc-wrapper li {
  font-size: 15px;
  font-weight: 400;
}

.ind-dashboard .chart-box {
  height: auto;
  padding: 30px;
}

.ind-dashboard:first-child .chart-box {
  padding: 43px;
}

.desc-wrapper-listing ul {
  padding: 0;
  margin-top: 30px;
}

.desc-wrapper-listing ul li::marker {
  font-size: 0;
}

.desc-wrapper-listing ul li p {
  font-size: 14px;
  line-height: 1;
}

.desc-wrapper-listing ul li p label {
  font-weight: 700;
  width: 100px;
  display: inline-block;
}

.button-wrapper,
.button-wrapper-start {
  text-align: right;
}

.assignment-wrapper > .ant-col:first-child {
  text-align: left;
  display: flex;
  align-items: center;
}

.assignment-wrapper label {
  cursor: pointer;
}

.assignment-wrapper input[type="checkbox"] {
  margin-right: 5px;
}

.button-wrapper button {
  height: 40px;
  padding: 6.4px 30px;
  font-size: 16px;
  border-radius: 5px;
  background: #13a300;
  color: #fff;
  line-height: 0;
  font-weight: 700;
}

.table-search {
  background-color: #fff;
  box-shadow: 0px 4px 30px 0px #0000001a;
  color: #000;
  cursor: pointer;
  font-size: 21px;
  padding: 8px;
  border-radius: 6px;
}

.button-wrapper button:hover,
.button-wrapper button:focus,
.button-wrapper button:active {
  background: #4fd83d;
  color: #fff;
  border-color: #4fd83d;
}

.row-highlight {
  background: #e3e3e3;
}
.grid_title {
  padding: 0;
  h1 {
    text-transform: uppercase;
    color: #000;
    letter-spacing: 2px;
    font-size: 15px;
  }
}
.send_grid_emails {
  background: white;
  padding: 20px 0;
  border-radius: 10px;

  .ant-row .ant-form-item {
    padding: 0 20px;
  }
  .send__btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    button {
      font-size: 15px;
      height: 40px;
      width: 100px;
      border-radius: 6px;
    }
  }
}

.button-wrapper a {
  height: 40px;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 5px;
  background: #51c5ff;
  color: #fff;
  transition: ease all 0.25s;
  font-weight: 700;
}

i.ant-menu-submenu-arrow {
  color: #fff;
}

.button-wrapper a:hover {
  background: #0083b7;
  color: #fff;
  border-color: #0083b7;
}

ul.ant-menu.ant-menu-sub.ant-menu-inline {
  background: #0083b7;
}

.dashboard-charts {
  height: 100%;
}

.ant-layout-sider-children,
ul.ant-menu.ant-menu-sub.ant-menu-inline li {
  background: #0083b7;
}

.dashboard-charts .chart-box div[data-chart-source-type="G2Plot"] {
  max-height: 500px;
}

.ind-dashboard .image-wrapper {
  height: auto;
  width: 100%;
  overflow: hidden;
}

.table-box.top-selling-courses
  .recent-orders
  .ant-table-thead
  > tr
  > th:first-child,
.table-box.top-selling-courses
  .recent-orders
  .ant-table-tbody
  > tr.ant-table-row
  > td:first-child {
  width: 5% !important;
}

.button-wrapper-start a {
  height: 40px;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 5px;
  background: #13a300;
  color: #fff;
  transition: ease all 0.25s;
  font-weight: 700;
}

.button-wrapper-start a:hover {
  background: #4fd83d;
  color: #fff;
  border-color: #4fd83d;
}

.invoice-wrapper .chart-box {
  padding: 50px;
}

.invoice-wrapper .chart-box .invoice-header h3 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

.invoice-wrapper .chart-box {
  height: 100%;
}

.invoice-wrapper .chart-box .order-details {
  margin: 25px 0;
}

.invoice-wrapper .chart-box .order-details ul,
.invoice-wrapper .chart-box .user-details ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.invoice-wrapper .chart-box .order-details ul li p,
.invoice-wrapper .chart-box .user-details ul li p {
  margin-bottom: 5px;
}

.invoice-wrapper .chart-box .order-details ul li p span {
  margin-left: 5px;
}

.invoice-wrapper .chart-box .order-details ul.text-right li {
  text-align: right;
}

.trainee-no-course-row {
  height: 500px;
}

.trainee-no-course-row p {
  text-transform: uppercase;
  color: #74859c;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.dashboard-home {
  padding: 24px;
}

.hideOnDesktop {
  display: block;
}

.logo-mob {
  display: none;
}

.ant-table-container {
  overflow: hidden;
  overflow-x: auto;
}

.logo {
  display: block;
  width: 100%;
  margin: 20px 0px;
  text-align: center;
  padding: 0 30px;
}

.logo.collapsed {
  padding: 0;
}

button.ant-btn.ant-btn-primary {
  background: #0083b7;
  border: 0;
}

.main-loader {
  margin: 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-box table {
  border-bottom: 1px solid #ececec;
}

.ant-modal-title {
  font-size: 18px !important;
  text-transform: uppercase;
  font-weight: 900 !important;
  color: #0083b7 !important;
}

.ant-modal-close-x {
  color: #fff;
  background-color: #0083b7;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

// .hideOnMobile {
//   display: none;
// }
.ant-layout-sider-zero-width-trigger span.anticon.anticon-bars svg {
  display: none !important;
}

.ant-layout-sider-zero-width-trigger:after {
  filter: invert(0);
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 40% !important;
  width: 40% !important;
  z-index: 9999;
  background: url("/assets/images/cross-icon.png") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin: auto;
}

.ant-layout-sider-zero-width .ant-layout-sider-zero-width-trigger:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 40% !important;
  width: 40% !important;
  z-index: 9999;
  background: url("/assets/images/menu-icon.png") !important;
  filter: invert(1);
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .hideOnMobile {
    display: block;
  }
}

.mobile-view {
  display: none;
}

@media screen and (max-width: 1200px) {
  .stats-heading h2 {
    padding: 5px !important;
    margin: 0;
  }

  .stats-heading h3 {
    font-size: 20px !important;
  }

  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .stats-heading p {
    margin: 0 auto;
    text-align: center;
    font-size: 10px !important;
    line-height: 1.2 !important;
  }

  .stats-heading h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .stats {
    margin: 0 0 15px 0;
    position: relative;
    height: auto !important;
  }

  .stats-heading {
    width: 150px;
    height: 100px;
  }

  .users-recent .ant-row > div {
    margin-top: 0;
  }

  .users-recent .ant-row > div:nth-child(3) {
    margin-top: 30px;
  }

  .stats-heading h2 {
    margin: 0;
  }

  .dashboard-home {
    padding: 0;
  }

  .invoice-wrapper .chart-box .invoice-header h3 {
    font-size: 18px;
  }

  .invoice-wrapper .chart-box .invoice-header .ant-col.ant-col-12 {
    margin: 0;
  }

  .invoice-wrapper .chart-box .invoice-header img {
    width: 70px;
    float: right;
  }

  .invoice-wrapper .chart-box {
    padding: 25px;
  }

  .invoice-wrapper .chart-box .order-details ul.text-right li {
    text-align: left;
  }

  .invoice-wrapper .chart-box .order-details > div {
    margin-top: 0;
  }

  .invoice-total .ant-row > div:not(:first-child) {
    margin: 0;
  }
}

.user-course-details .admin-userpage-search .ant-select-show-search {
  width: 100%;
}

.user-course-details .admin-userpage-search .search-form-wrapper {
  margin: 0px 0px 20px;
  display: flex;
}

.user-course-details .admin-userpage-search {
  width: 100%;
}

.user-course-details {
  width: 100%;
}

.mobile-logout-btn {
  display: none;
}

@media screen and (max-width: 767px) {
  .ant-modal-centered .ant-modal {
    width: 100% !important;
    max-width: 90%;
  }
  .send_grid_emails .ant-row > div:not(:first-child) {
    margin-top: 0 !important;
  }

  .tab-logout-btn {
    display: none;
  }

  .mobile-logout-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 0;

    button {
      height: fit-content;
      height: -moz-fit-content;
      width: fit-content;
      width: -moz-fit-content;
      line-height: 1;
      background: none;
      border: none;
      text-transform: capitalize;
      color: #74859c;
      font-size: 15px;
    }
  }

  .userActionBtns .ant-space-item {
    margin: auto !important;
  }

  .userActionBtns button {
    margin-bottom: 5px;
  }

  .logo {
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome-note {
    display: none;
  }

  .ant-row.mblrev {
    flex-direction: column-reverse;
  }

  .logo-mob {
    display: inline-flex;
    width: 50%;
    justify-content: flex-end;
    margin: 5px -5% 0 5%;

    a {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .w-full {
    width: 100%;
  }

  .ant-layout-sider {
    position: fixed !important;
    top: 0;
    bottom: 0;
    z-index: 99;
    width: 100% !important;
    max-width: 100% !important;
  }

  .main-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }

  .ant-layout-sider-zero-width.ant-layout-sider {
    width: 0% !important;
    max-width: 100% !important;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 10px !important;
    transition: Ease all 0.25s !important;
    left: calc(100% - 55px);
    background: #fff !important;
    color: #001529 !important;
  }

  .ant-layout-sider-zero-width .ant-layout-sider-zero-width-trigger {
    top: 10px !important;
    left: 15px;
    background: rgb(0, 131, 183) !important;
    color: #fff !important;
    width: 42px;
    border-radius: 10px;
  }

  .ant-layout-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }

  .ant-row > div:not(:first-child) {
    margin-top: 30px;
  }

  .ant-row.mblrev > div:first-child {
    margin-top: 30px;
  }

  .ant-row.mblrev > div:not(:first-child) {
    margin-top: 0;
  }

  .users-recent .ant-row > div {
    margin-top: 0;
  }

  main.ant-layout-content {
    margin: 0 !important;
    margin-top: 55px !important;
  }

  .ant-col.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-lg-8.ant-col-xl-8 {
    margin: 0;
  }

  .ant-menu-submenu-popup {
    position: fixed !important;
  }

  .ant-table-content {
    overflow: hidden;
    overflow-x: auto;
  }

  .ant-table-wrapper.blogcat-table table {
    width: 100%;
    display: block;
  }

  .stdmycours-table .ant-table-thead > tr,
  .stdmycours-table .ant-table-tbody > tr,
  .recent-orders .ant-table-thead > tr,
  .recent-orders .ant-table-tbody > tr,
  .ant-table-wrapper.blogs-table .ant-table-thead > tr,
  .ant-table-wrapper.blogs-table .ant-table-tbody > tr,
  .ant-table-wrapper.promocode-table .ant-table-thead > tr,
  .ant-table-wrapper.promocode-table .ant-table-tbody > tr {
    display: flex;
  }

  .ant-table-wrapper.recent-orders .ant-table-thead > tr th,
  .ant-table-wrapper.recent-orders .ant-table-tbody > tr td,
  .ant-table-wrapper.stdmycours-table .ant-table-thead > tr th,
  .ant-table-wrapper.stdmycours-table .ant-table-tbody > tr td,
  .ant-table-wrapper.blogs-table .ant-table-thead > tr th,
  .ant-table-wrapper.blogs-table .ant-table-tbody > tr td {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }

  .ant-table-wrapper.recent-orders .ant-table-thead > tr th:nth-child(1),
  .ant-table-wrapper.recent-orders .ant-table-tbody > tr td:nth-child(1),
  .ant-table-wrapper.stdmycours-table .ant-table-thead > tr th:nth-child(1),
  .ant-table-wrapper.stdmycours-table .ant-table-tbody > tr td:nth-child(1),
  .ant-table-wrapper.blogs-table .ant-table-thead > tr th:nth-child(1),
  .ant-table-wrapper.blogs-table .ant-table-tbody > tr td:nth-child(1) {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
  }

  .ant-table-wrapper.faqs-table .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-wrapper.faqs-table .ant-table-thead > tr > th:nth-child(3),
  .ant-table-wrapper.pages-table .ant-table-tbody > tr > td:nth-child(4),
  .ant-table-wrapper.pages-table .ant-table-thead > tr > th:nth-child(4) {
    display: none;
  }

  .ant-form .ant-form-item > div {
    margin: 0;
  }
}

.lghide {
  display: none;
}

.desc-wrapper-listing ul {
  margin-top: -5%;
  margin-bottom: 15px;
}

.desc-wrapper-listing ul li p {
  display: block !important;
  margin: 0;
}

.desc-wrapper-listing ul li label {
  font-weight: 700;
  width: 25%;
  font-size: 12px;
  line-height: 1;
  margin-top: 5%;
}

.desc-wrapper-listing ul li {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .ind-dashboard .image-wrapper img {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }

  .ind-dashboard .desc-wrapper p {
    display: none;
  }

  .user-course-details .admin-userpage-search .search-form-wrapper {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
  }

  .user-profile .user-course-details .courses-wrapper .course-box .course-desc {
    padding: 0;
  }

  .desc-wrapper-listing ul li label {
    width: 35%;
  }

  .std.button-wrapper {
    text-align: center;
  }

  .std.button-wrapper button:first-child {
    margin-bottom: 15px;
  }

  .admin-recent-orders .ant-table-content {
    overflow: auto;
  }

  .user-profile
    .user-course-details
    .courses-wrapper
    .course-box
    .course-desc
    .course-title {
    font-size: 16px;
    line-height: 1.2;
  }

  .smhide {
    display: none;
  }

  .lghide {
    display: inline-block;
  }

  .user-profile
    .user-course-details
    .courses-wrapper
    .course-box
    .course-desc
    .remove-course,
  .user-profile
    .user-course-details
    .courses-wrapper
    .course-box
    .course-desc
    .course-certificate {
    top: -20px;
  }

  .user-profile
    .user-course-details
    .courses-wrapper
    .course-box
    .course-desc
    .course-points
    > div {
    margin-top: 0;
    width: 50%;
    flex: 0 0 50%;
  }

  .user-profile .user-course-details .courses-wrapper .course-box:last-child {
    padding: 10px;
  }

  .user-profile .user-course-details .courses-wrapper {
    height: auto;
    max-height: 100%;
    padding: 0;
  }

  .user-profile .user-course-details .courses-wrapper.no-courses {
    max-height: inherit;
    height: auto;
  }
}

.db-charts-main {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.db-charts-main .ant-table-content {
  overflow-x: auto;
  width: 100%;
}

.db-charts-main > .ant-row {
  width: calc(100% + 30px) !important;
  max-width: calc(100% + 30px) !important;
}

.dashboard-charts td.ant-table-cell .trainee-course:not(:first-child) p,
.parentCourse .man-course-report:not(:first-child) p,
.parentCourse .course-report:not(:first-child) p {
  margin-top: 5px;
  padding-top: 5px;
  display: block;
  border-top: 1px solid #f2f2f2;
}
tr.ant-table-row.ant-table-row-level-0 {
  transition: ease all 1s;
}
.highlight-row > td {
  background-color: lightblue;
}
.header-menu-wrapper {
  text-align: right;
}
